import { graphql } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductMasonryGrid from "../components/shop/product-masonry-grid"
import ShopHeader from "../components/shop/shop-header"
import ShopIntro from "../components/shop/shop-intro"
import { ContentWrapper, Flex, TextWrapper, Wrapper } from "../layout/reusables"
import { useInterval } from "react-use"
import { MainButton } from "../styles/buttons"
import { STORE_LINK } from "../../project-constants"
import { HeadlineText} from "../styles/typography"

const Quote = styled.h5`
    font-size: 40px;
    line-height: 1.5;
    text-align: center;
    padding: 5vh 20px;
`

const ShopPage = ({ data }) => {
    const { pageData } = data
    const { shop_page } = pageData
    const {
        cover,
        title,
        subtitle,
        sectionTitle,
        sectionParagraph,
        separatingParagraph,
        gallery,
    } = shop_page ?? ""

    const [mainImage, setMainImage] = useState(null)
    const [secondaryImage, setSecondaryImage] = useState(null)
    const [showing, setShowing] = useState(true)

    const delay = 8000
    const isRunning = true

    useInterval(
        () => {
            setShowing(false)
            setNewImages()
            setTimeout(() => {
                setShowing(true)
            }, 200)
        },
        isRunning ? delay : null
    )

    const shuffleArray = array => {
        const oldArray = [...array]
        for (let i = oldArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1))
            ;[oldArray[i], oldArray[j]] = [oldArray[j], oldArray[i]]
        }
        return oldArray
    }

    const setNewImages = () => {
        //Randomize the array and grab the first two 🤷🏾‍♂️
        const galleryToShuffle = gallery
        const shuffledImages = shuffleArray(galleryToShuffle)

        const shuffledTallImages = shuffledImages.filter(
            el => el.image.mediaDetails.height > el.image.mediaDetails.width
        )
        const shuffledWideImages = shuffledImages.filter(
            el => el.image.mediaDetails.height < el.image.mediaDetails.width
        )
        setMainImage(
            shuffledTallImages[0].image.localFile.childImageSharp.fluid
        )
        setSecondaryImage(
            shuffledWideImages[0].image.localFile.childImageSharp.fluid
        )
    }

    return (
        <Layout>
            <SEO title="Shop" />
            <Wrapper>
                <ShopHeader
                    eyebrow={subtitle}
                    title={title}
                    image={cover.localFile.childImageSharp.fluid}
                />
                <ContentWrapper style={{ maxWidth: 1200 }}>
                    <Flex style={{padding: '80px 0 40px 0'}} direction="column" align="center" justify="center">
                        <HeadlineText style={{marginBottom: 20, fontSize: 35, textAlign: 'center'}}>Our store is LIVE</HeadlineText>
                        <a target="_blank" rel="noreferrer" style={{color: 'inherit'}} href={STORE_LINK}><MainButton dark>Shop PHEAL Now</MainButton></a>
                    </Flex>
                    <ShopIntro
                        title={sectionTitle}
                        copy={sectionParagraph}
                        mainImage={
                            mainImage ?? data.alicia.childImageSharp.fluid
                        }
                        secondaryImage={
                            secondaryImage ?? data.asia.childImageSharp.fluid
                        }
                        showing={showing}
                    />
                    <TextWrapper
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            margin: "0 auto",
                            maxWidth: "80ch",
                        }}
                    >
                        <Quote>{separatingParagraph}</Quote>
                    </TextWrapper>
                    <ProductMasonryGrid gallery={gallery} />
                </ContentWrapper>
            </Wrapper>
        </Layout>
    )
}

export default ShopPage

export const query = graphql`
    query {
        pageData: wpPage(title: { eq: "Shop" }) {
            shop_page {
                cover {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1500, quality: 100) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
                title
                subtitle
                separatingParagraph
                sectionTitle
                sectionParagraph
                gallery {
                    concept
                    creativeDirection
                    featuredItems
                    fieldGroupName
                    styling
                    talent
                    photography
                    image {
                        sourceUrl
                        mediaDetails {
                            height
                            width
                        }
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 800, quality: 100) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }
        }
        file(relativePath: { eq: "shop-cover.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        alicia: file(relativePath: { eq: "alicia-shot.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        asia: file(relativePath: { eq: "asia-shot.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`
