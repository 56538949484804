import { motion } from "framer-motion"
import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { DigestiveText } from "../../styles/typography"
import { Flex } from "../../layout/reusables"
import Div100vh from "react-div-100vh"
import { colors } from "../../styles/colors"

const ShopHeader = ({ image, eyebrow, title }) => {
    return (
        <Container>
            <CoverContainer>
                <Img fluid={image} style={{ width: "100%", height: "100%" }} />
            </CoverContainer>
            <Content direction="column" align="flex-start">
                <Eyebrow>{eyebrow}</Eyebrow>
                <Title
                    transition={{ delay: 0.3, duration: 1 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                >
                    {title}
                </Title>
            </Content>
        </Container>
    )
}

export default ShopHeader

const Container = styled(Div100vh)`
    background: black;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    position: relative;
    padding: 20px;
`
const CoverContainer = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    margin: 0;
`
const Content = styled(Flex)`
    z-index: 1;
    padding: 5%;
`
const Eyebrow = styled.p`
    color: ${colors.yellow};
    font-size: clamp(18px, 20px);
    text-transform: uppercase;
    text-decoration: uppercase;
`
const Title = styled(DigestiveText)`
    font-size: clamp(90px, 20vw, 220px);
    text-align: left;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    z-index: 2;
    color: ${colors.yellow};
    margin-bottom: 40px;
`
