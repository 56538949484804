import React from "react"
import styled from "styled-components"
import { TextWrapper } from "../../layout/reusables"
import { BodyCopy, HeadlineText } from "../../styles/typography"
import Img from "gatsby-image"
import { AnimatePresence, motion } from "framer-motion"
import { STORE_LINK } from '../../../project-constants'

const ShopIntro = ({ mainImage, secondaryImage, title, copy, showing }) => {
    return (
        <Container>
            <AnimatePresence>
                {showing && (
                    <motion.div
                        key={mainImage}
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                    >
                        <a target="_blank" rel="noreferrer" style={{color: 'inherit'}} href={STORE_LINK}>
                            <Img
                                key={mainImage}
                                fluid={mainImage}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    maxHeight: "90vh",
                                }}
                            />
                        </a>
                    </motion.div>
                )}
            </AnimatePresence>
            <Content>
                <TextWrapper style={{ marginBottom: 30, maxWidth: "55ch" }}>
                    <Title>{title}</Title>
                    <BodyCopy>{copy}</BodyCopy>
                </TextWrapper>
                <AnimatePresence>
                    {showing && (
                        <motion.div
                            key={secondaryImage}
                            initial={{ opacity: 0 }}
                            exit={{ opacity: 0 }}
                            style={{
                                alignSelf: "flex-end",
                                height: "100%",
                                maxHeight: "55vh",
                            }}
                            animate={{ opacity: 1 }}
                        >
                            <a target="_blank" rel="noreferrer" style={{color: 'inherit'}} href={STORE_LINK}>
                                <Img
                                    fluid={secondaryImage}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        // maxHeight: "55vh",
                                    }}
                                />
                            </a>
                        </motion.div>
                    )}
                </AnimatePresence>
            </Content>
        </Container>
    )
}

export default ShopIntro

const Container = styled.section`
    padding: 5vh 0 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    height: 90vh;
    overflow: hidden;

    @media (max-width: 1024px) {
        height: 70vh;
    }

    @media (max-width: 768px) {
        padding: 5vh 20px 0;
        gap: 20px;
    }
    @media (max-width: 650px) {
        height: auto;
        grid-template-columns: 1fr;
    }
`

const Content = styled.div`
    padding-top: 30px;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
`
const Title = styled(HeadlineText)`
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 15px;
    font-weight: 700;
`
