import React from "react"
import styled from "styled-components"
import Masonry from "react-masonry-css"
import "./masonry-style.css"
import LookBookItem from "./lookbook-item"
import { useMedia } from "react-use"
import { DigestiveText } from "../../styles/typography"

const ProductMasonryGrid = ({ gallery }) => {
    const isWide = useMedia("(min-width: 768px)")
    return (
        <Container>
            <Title>Shop Pheal</Title>
            <Masonry
                breakpointCols={isWide ? 3 : 2}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {gallery.map((item, index) => (
                    <LookBookItem key={index} index={index} item={item} />
                ))}
            </Masonry>
        </Container>
    )
}

export default ProductMasonryGrid

const Container = styled.section`
    padding: 5vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Title = styled(DigestiveText)`
    font-size: clamp(60px, 8vw, 110px);
    text-align: center;
    color: black;
    padding: 7vh 20px;
`